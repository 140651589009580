<template>
  <div style="padding-left: 1.75rem;">
    <div class="col2_head">
      <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      {{ $route.name }}
      <div class="subHeadTitle">{{ $route.name }}</div>
      <template v-if="PERMISIONS.create">
        <div class="right" style="margin-top: 1.2rem;">
          <div @click="addItem" class="Button1">
            <div class="icon">
              <img
                @load="imgLoaded"
                src="../assets/media/for_table/circle_ADD_new_etap.svg"
                alt=""
              />
            </div>
            <span class="Btn_content">
              Adaugă u.m.
            </span>
          </div>
        </div>
      </template>
    </div>
    <template v-if="PERMISIONS.list">
      <table-parent
        :reloadCount="reloadTable"
        :tableHead="tableHead"
        :prepareFn="prepareData"
        :apiModule="tableModule"
        :apiModuleName="'MEASUREMENT_UNIT_PAGINATED'"
        :tableMinimizeHead="true"
        :tableSpread="true"
        @Btn2="editItem"
        @Btn3="delItem"
        @totalRecords="(x) => (totalRecords = x)"
      />
    </template>
    <template v-else>
      <h3 style="display: flex; flex-direction: column; align-items: center;">
        <span style="font-size: 4rem;">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        Acces interzis
      </h3>
    </template>
  </div>
</template>

<script>
import PermisionsMixin from "@/mixins/measurmentUnitPermMixin.js";
import {
  MEASUREMENT_UNIT_PAGINATED,
  MEASUREMENT_UNIT_ADD,
  MEASUREMENT_UNIT_MODIFY,
  MEASUREMENT_UNIT_DELETE,
} from "../api.js";
import TableParent from "@/components/TableParent";
import DialogModal from "@/components/DialogModal";

export default {
  $Title() {
    return `ConectX - ${this.$route.name}`;
  },
  inject: ["mu"],
  mixins: [PermisionsMixin],
  components: {
    TableParent,
  },
  data() {
    return {
      reloadTable: 0,
      tableHead: [
        {
          title: "Denumire unitate de măsură",
          sort: true,
          queryKey: "shortName",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Descriere unitate de măsură",
          sort: true,
          queryKey: "description",
          bottomHead: {
            quikFiltre: true,
          },
        },
      ],
      tableModule: MEASUREMENT_UNIT_PAGINATED,
      totalRecords: 0,
    };
  },
  watch: {
    mu: {
      handler() {
        this.reloadPage();
      },
      deep: true,
    },
  },
  methods: {
    reloadPage() {
      this.reloadTable++;
      this.totalRecords = 0;
    },
    delItem(item) {
      if (!Number.isInteger(item?.id)) {
        this.$toastr.e("Anunțați administratorul.");
        return;
      }

      this.$modal.show(
        DialogModal,
        {
          target: "DeleteMU",
          title: "Ștergere unitate de măsură",
          content:
            '<span style="color:#f44336;display: block;margin-top: 2rem;"><i class="fas fa-exclamation-circle" style="font-size: 6rem;"></i> <p style="font-weight: 500;font-size: 2.2rem;">Ești sigur că vrei să ștergi unitatea de măsură selectată?</p></span>',
          closeBtnText: "Anulează",
          button: {
            type: 3,
            value: "Șterge",
            handler: () => {
              const err = (msg) =>
                this.$toastr.e(msg || "Ștergerea unității de măsură a eșuat.");

              this.setSafeLoad();
              MEASUREMENT_UNIT_DELETE(item.id)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s(
                      "Unitatea de măsură a fost ștearsă cu succes."
                    );
                  } else {
                    err();
                  }
                  this.setLoad();
                  this.reloadPage();
                })
                .catch((msg) => {
                  err(msg);
                  this.setLoad();
                  this.reloadPage();
                });
            },
          },
        },
        {
          name: "DeleteMU",
          adaptive: true,
          width: "650",
          height: "400",
        }
      );
    },
    editItem(item) {
      if (!Number.isInteger(item?.id)) {
        this.$toastr.e("Anunțați administratorul.");
        return;
      }

      this.$modal.show(
        DialogModal,
        {
          target: "EditMU",
          title: "Modifică unitate de măsură",
          inputs: [
            {
              id: "shortName",
              label: "Denumire unitate de măsură",
              errReason: "Introduceți denumirea unității de măsură.",
            },
            {
              id: "description",
              label: "Descriere unitate de măsură",
              errReason: "Introduceți descrierea unității de măsură.",
            },
          ],
          closeBtnText: "Anulează",
          initialInputsData: item,
          button: {
            type: 2,
            value: "Modifică",
            handler: (sendData) => {
              sendData = this.getEdited(sendData, item, true);

              const error = (msg) => {
                this.$toastr.e(
                  msg || "Modificarea unității de măsură a eșuat."
                );
                this.reloadPage();
                this.setLoad();
              };

              this.setSafeLoad(12000);
              MEASUREMENT_UNIT_MODIFY(item.id, sendData)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Unitatea de măsură a fost modificată.");
                    this.reloadPage();
                    this.setLoad();
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "EditMU",
          adaptive: true,
          width: "650",
          height: "800",
        }
      );
    },
    addItem() {
      this.$modal.show(
        DialogModal,
        {
          target: "AddMU",
          title: "Adaugă unitate de măsură",
          inputs: [
            {
              id: "shortName",
              label: "Denumire unitate de măsură",
              errReason: "Introduceți denumirea unității de măsură.",
            },
            {
              id: "description",
              label: "Descriere unitate de măsură",
              errReason: "Introduceți descrierea unității de măsură.",
            },
          ],
          closeBtnText: "Anulează",
          button: {
            type: 2,
            value: "Adaugă",
            handler: (sendData) => {
              const error = (msg) => {
                this.$toastr.e(msg || "Adăugarea unității de măsură a eșuat.");
                this.reloadPage();
                this.setLoad();
              };

              this.setSafeLoad(12000);
              MEASUREMENT_UNIT_ADD(sendData)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Unitatea de măsură a fost adăugată.");
                    this.reloadPage();
                    this.setLoad();
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "AddMU",
          adaptive: true,
          width: "650",
          height: "800",
        }
      );
    },
    prepareData(row) {
      const spacer = window.innerWidth > 900;

      const preparation = [
        row.shortName || "-",
        row.description
          ? { val: row.description, tooltip: row.description }
          : "-",
      ];

      if (spacer) {
        preparation.push({ spacer: true });
      }

      const uuid = row.createdByUser;
      const x = this.PERMISIONS;
      const prepareBtn = [];

      const checkOwner = (o) => {
        if (!o) return false;
        if (o === true) return true;
        if (o == uuid) {
          return true;
        }
        if (Array.isArray(o) && o[1] == uuid) {
          return true;
        }
        return false;
      };

      if (checkOwner(x.edit)) {
        prepareBtn.push(2);
      }
      if (checkOwner(x.delete)) {
        prepareBtn.push(3);
      }
      if (prepareBtn.length) {
        preparation.push([prepareBtn]);
      }

      return preparation;
    },
  },
  async created() {
    if (!this.PERMISIONS.list) {
      return;
    }
    if (window.innerWidth > 900) {
      this.tableHead.push({ spacer: true });
    }

    const baseKeys = ["create", "edit", "delete"];
    if (this.checkParamsInObj(this.PERMISIONS, baseKeys)) {
      this.tableHead.push({
        spacer: true,
        minimize: 1,
        fixed: {
          right: true,
        },
        bottomHead: {
          quikFiltre: "clear",
        },
      });
    }
  },
};
</script>
